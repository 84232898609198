// Feature flags for https://github.com/sergiodxa/flagged

export const FEATURE_LEFT_NAV_MENU_POFILE = 'FEATURE_LEFT_NAV_MENU_POFILE';
export const FEATURE_SETTINGS_LIST = 'FEATURE_SETTINGS_LIST';
export const FEATURE_ADD_TOKEN_BUTTON = 'FEATURE_ADD_TOKEN_BUTTON';
export const FEATURE_LANDING = 'FEATURE_LANDING';
export const FEATURE_SETTINGS_CLOSE_ACCOUNT = 'FEATURE_SETTINGS_CLOSE_ACCOUNT';
export const FEATURE_SETTINGS_FREE_TRANSACTIONS = 'FEATURE_SETTINGS_FREE_TRANSACTIONS';
export const FEATURE_NAV_MENU_BUY_BUTTON = 'FEATURE_NAV_MENU_BUY_BUTTON';

export const featureFlags = {
  [FEATURE_LEFT_NAV_MENU_POFILE]: false,
  [FEATURE_SETTINGS_LIST]: false,
  [FEATURE_ADD_TOKEN_BUTTON]: false,
  [FEATURE_LANDING]: true,
  [FEATURE_SETTINGS_CLOSE_ACCOUNT]: false,
  [FEATURE_SETTINGS_FREE_TRANSACTIONS]: false,
  [FEATURE_NAV_MENU_BUY_BUTTON]: true,
};
