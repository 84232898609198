// TODO: maybe move in to above folder cause it is use in TokenAvatar

import { useMemo } from 'react';

import type { TokenInfo, TokenListContainer } from '@solana/spl-token-registry';
import { createContainer } from 'unstated-next';

import { SOL_MINT } from '../constants';

const SOL_TOKEN_INFO = {
  chainId: 101,
  address: SOL_MINT.toString(),
  name: 'Native SOL',
  decimals: 9,
  symbol: 'SOL',
  logoURI: 'https://cdn.jsdelivr.net/gh/trustwallet/assets@master/blockchains/solana/info/logo.png',
  tags: [],
  extensions: {
    website: 'https://solana.com/',
    serumV3Usdc: '9wFFyRfZBsuAha4YcuxcXLKwMxJR43S7fPfQLusDBzvT',
    serumV3Usdt: 'HWHvQhFmJB3NUcu1aihKmrKegfVxBEHzwVX6yZCKEsi1',
    coingeckoId: 'solana',
    waterfallbot: 'https://t.me/SOLwaterfall',
  },
};

export interface UseTokenList {
  tokenMap: Map<string, TokenInfo>;
}

export interface UseTokenListArgs {
  tokenList: TokenListContainer;
}

const useTokenListInternal = (props: UseTokenListArgs): UseTokenList => {
  const tokenList = useMemo(() => {
    const list = props.tokenList.filterByClusterSlug('mainnet-beta').getList();
    // Manually add a fake SOL mint for the native token. The component is
    // opinionated in that it distinguishes between wrapped SOL and SOL.
    list.push(SOL_TOKEN_INFO);

    return list;
  }, [props.tokenList]);

  // Token map for quick lookup.
  const tokenMap = useMemo(() => {
    const tokenMap = new Map();

    tokenList.forEach((t: TokenInfo) => {
      tokenMap.set(t.address, t);
    });

    return tokenMap;
  }, [tokenList]);

  return {
    tokenMap,
  };
};

export const { Provider: TokenListProvider, useContainer: useTokenList } = createContainer<
  UseTokenList,
  UseTokenListArgs
>(useTokenListInternal);
